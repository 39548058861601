import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
export default function MultiActionAreaCard() {
  return (
    <Card sx={{ width: "70%", px: "5%", py: "3%" }}>
        <CardContent>
          <Typography gutterBottom variant="h2" component="div" align="center" >
            About Me
          </Typography>
          <Typography variant="h5" color="text.secondary" align="justify">
          As a robotics engineer with a wealth of technical skills and practical experience, 
          I am a highly motivated and hardworking individual who excels at problem-solving 
          and delivering successful outcomes. I am also a quick learner, able to adapt to 
          new challenges and technologies with ease. In addition to my technical expertise, 
          I am an effective team leader and project manager, with a proven track record of 
          successfully managing and motivating team members to achieve our shared goals. 
          Whether working on complex projects or collaborating with others to drive innovation, 
          I am dedicated to delivering results and making a positive impact in the field of 
          robotics
          </Typography>
        </CardContent>
    </Card>
  );
}