import React from 'react'
import { 
    TextField,
    Button,
    Grid,
    Stack, 
   } from '@mui/material'

const Form = () => {
  return (
      <Grid item sm={1} sx={{
            display: "flex",
            alignItems: "center", 
            justifyContent: "center"
        }}>
            <form>
            <Stack spacing={2} width="30vw" alignItems="center">     
                <TextField size='small' label="Full Name" sx={{width: "100%",}}/>
                <TextField size='small' label="Email" sx={{width: "100%",}}/>
                <TextField multiline label="Message" sx={{width: "100%",}}/>
                <Button variant="outlined" type="submit" sx={{width: "70%",}}>Submit</Button>
            </Stack>
            </form>
        </Grid>
  )
}

export default Form
