import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import myImage from "../../Image/boy.png"
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';



const Hero = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} columns={3}>
      <Grid item sm={2} sx={{
            display: "flex",
            alignItems: "center", 
            justifyContent: "center"
        }}>
            <Box sx={{width: "70%"}}>
            <Typography variant="h6" component="div" sx={{color: "#C7C7C7"}}>
                HELLO MY NAME IS
            </Typography>
            <Typography variant="h3" component="div" sx={{color: "#3A3A3A"}}>
                VIVEK KUMAR 
            </Typography>
            <Typography variant="h6" component="div" sx={{color: "#C7C7C7"}}>
            "As a robotics engineer with strong technical skills, I am well-equipped to tackle complex challenges and drive successful outcomes in the 
            field of robotics." 
            {/* a proven track record 
            of quick learning and effective team leadership, and expertise in project management,  */}
             
            </Typography>
            <Link to="Contact" style={{ textDecoration: 'none' }}>
            <Button variant="contained" sx={{bgcolor: "#191718", mt: "5%"}}>
                Contact Me
            </Button>
            </Link>
            </Box>
           
        </Grid>
        <Grid item sm={1} sx={{
            display: "flex",
            alignItems: "center", 
            justifyContent: "center",
        }}>
            <Box sx={{width: "70%", display: "flex",alignItems: "left", 
            justifyContent: "left", marginBottom: "-20%",}}>
            <img width="120%"  src={myImage} alt="coming soon..."/>
            </Box>
        
        </Grid>
      </Grid>
    </Box>
  )
}

export default Hero;
