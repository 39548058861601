import './App.css';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Appbar from "./Components/AppBar/Appbar"
import Home from './Components/Home/Home';
import Robots from './Components/Robots/Robots';
import Other from './Components/Other/Other';
import Gallery from './Components/Gallery/Gallery';
import Contact from './Components/Contact/Contact';


function App() {
  return (
    <>
    <BrowserRouter>
        <Appbar/>
       <Routes>
       <Route index element={<Home />} />
      <Route path='Home' element={<Home />} />
      <Route path='Robotics' element={<Robots />} />
      <Route path='Automobiles' element={<Other />} />
      <Route path='Gallery' element={<Gallery />} />
      <Route path='Contact' element={<Contact />} />
      </Routes>  
    </BrowserRouter>
    </>
    
  );
}

export default App;
