import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function CustomImageList() {
  return (
    <ImageList
      sx={{
        width: '100%',
        height: '100%',
        // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
        transform: 'translateZ(0)',
      }}
      rowHeight={'auto'}
      gap={1}
    >
      {itemData.map((item) => {
        const cols = item.featured ? 2 : 1;
        const rows = item.featured ? 2 : 1;

        return (
          <ImageListItem key={item.img} cols={cols} rows={rows}>
            <img
              {...srcset(item.img, 250, 200, rows, cols)}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              sx={{
                background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
              }}
              title={item.title}
              position="top"
              actionIcon={
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label={`star ${item.title}`}
                >
                  <StarBorderIcon />
                </IconButton>
              }
              actionPosition="left"
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
}

const itemData = [
  {
    img: 'https://inc42.com/wp-content/uploads/2018/01/mitra-feature.jpg',
    title: 'Breakfast',
    author: '@bkristastucchio',
    featured: true,
  },
  {
    img: 'https://qph.cf2.quoracdn.net/main-qimg-082656b6d2cab41f22539ce276d76a09-lq',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: 'https://www.businessinsider.in/photo/78189865/IN-PICS-Mitra-and-other-humanoid-robots-help-COVID-19-patients-stuck-in-quarantine-across-hospitals-in-India.jpg',
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img: 'https://mitrarobot.com/images/blog1.jpeg',
    title: 'Coffee',
    author: '@nolanissac',
  },
  {
    img: 'https://c.ndtvimg.com/mitra_facebook_650_625x300_1529599622470.jpg?im=Resize=(1230,900)',
    title: 'Hats',
    author: '@hjrc33',
  },
  {
    img: 'https://www.electronicsforu.com/wp-contents/uploads/2020/08/4-2.jpg',
    title: 'Honey',
    author: '@arwinneil',
    featured: true,
  },
  {
    img: 'https://mitrarobot.com/event_rental/images/senior_care/BA_4.png',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    img: 'https://qph.cf2.quoracdn.net/main-qimg-b63bbc48eec5589b35c8d80e239b37cf-lq',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    img: 'https://mitrarobot.com/images/senior_care/sc2.png',
    title: 'Mushrooms',
    author: '@silverdalex',
  },
  {
    img: 'https://mitrarobot.com/images/corporate_healthcare/c_h2.jpg',
    title: 'Tomato basil',
    author: '@shelleypauls',
  },
  {
    img: 'https://spiderimg.amarujala.com/assets/images/2017/11/30/mitra-robot_1512038274.jpeg',
    title: 'Sea star',
    author: '@peterlaster',
  },
  {
    img: 'https://mitrarobot.com/images/senior_care/sc1.png',
    title: 'Bike',
    author: '@southside_customs',
  },
];