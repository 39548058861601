import { Container } from '@mui/system'
import React from 'react'
import Hero from './Hero'
import About from "./About"

const Home = () => {
  return (
    <div>
        <Container maxWidth="100vw" sx={{
            bgcolor: "#F6F6F6",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center", 
            justifyContent: "center", 
            }}>
            <Hero/>
        </Container>
        <Container maxWidth="100vw" sx={{
            bgcolor: "#C7C7C7",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center", 
            justifyContent: "center",}} >

              <About/>

        </Container>
      
    </div>
  )
}

export default Home
