import React from 'react'
import { Typography } from '@mui/material'
import { Container } from '@mui/system'

const Other = () => {
  return (
    <Container maxWidth="100vw" sx={{
            bgcolor: "#191718",
            color: "#ffffff",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center", 
            justifyContent: "center",
    }}>
        <Typography variant="h1" component="div">
        Coming soon Automobiles
      </Typography>
    </Container>
  )
}

export default Other
