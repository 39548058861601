import { Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Divider from '@mui/material/Divider';


import React from 'react'
const Robots = () => {
  return (
    <div>
    <Container sx={{
       
        minHeight: "100vh",
        display: "block",
        alignItems: "center",
        justifyContent: "center",
    }}>
        <Typography variant="h3" component="div" textAlign="center" padding="2%" paddingTop="10%">
        Robot World: An Overview of the Futuristic Robots I've Created
        </Typography>
        <Grid>
        <img
        src={'https://i.ibb.co/TMM6mM8/robots.jpg'}
        alt={"miniMitra"}
        loading="lazy"
        width="100%"
      />
        </Grid>
        
        <Typography variant='p' component="div" textAlign="justify" padding="3%" >
        As a seasoned robotics expert with years of experience in the field, 
        I have developed a diverse range of robots, including both humanoids and other types. 
        My expertise in mechanical design, artificial intelligence, and machine learning has 
        enabled me to create advanced and sophisticated robots capable of a wide range of functions.
        In my portfolio, you will find a variety of robots that I have designed and built over the years, 
        including both humanoids that can interact with people and industrial robots that can assist with 
        tasks such as telemedicine, thermal screening, and UV disinfection. My portfolio showcases my expertise 
        in the field and offers a range of robots with various capabilities, from those with advanced AI 
        capabilities to simple machines that can help with specific tasks. Whether you are looking for a 
        sophisticated robot or a more practical solution, you will find a suitable option in my portfolio.
        </Typography>
    </Container>
    <Divider sx={{marginBottom: "5%"}}></Divider>
    <Container sx={{
      minHeight: "100vh",
      display: "block",
      alignItems: "center",
      justifyContent: "center",  }}>
      <Typography variant="h1" component="div" textAlign="center" padding="2%">
      Mitra
      </Typography>
      
      <img
        src={'https://i.ibb.co/d6zcnGN/Mitra.jpg'}
        alt={"miniMitra"}
        loading="lazy"
        width="100%"
      />
      <Typography variant='p' component="div" textAlign="justify" padding="3%" >
      Mitra is a sophisticated humanoid robot that leverages artificial intelligence (AI) and machine learning (ML) technologies to facilitate social interaction and autonomous navigation. Equipped with the ability to engage in conversation, recognize nonverbal cues, and identify individuals through face detection and recognition, Mitra is designed to interact with humans in a natural and intuitive manner. Its autonomous navigation capabilities allow it to move and interact with its environment, making it suitable for deployment in a variety of settings such as hospitals, hotels, airports, and malls. Additionally, its fleet management capabilities enable the efficient coordination and deployment of multiple robots to different locations. Overall, Mitra represents a cutting-edge application of AI and ML technologies in the field of robotics.
      </Typography>

  </Container>
  <Divider sx={{marginBottom: "5%"}}></Divider>
  <Container sx={{
      minHeight: "100vh",
      display: "block",
      alignItems: "center",
      justifyContent: "center",
  }}>
      <Typography variant="h1" component="div" textAlign="center" padding="2%">
      Mitra 2.0
      </Typography>
      <img
        src={'https://i.ibb.co/HHYBDxm/Mini-Mitra.jpg'}
        alt={"miniMitra"}
        loading="lazy"
        width="100%"
      />
      <Typography variant='p' component="div" textAlign="justify" padding="3%" >
      Mitra 2.0 is a specialized version of the original Mitra robot, specifically designed for senior care applications. Its non-humanoid form is tailored to the needs of seniors, with a height that allows them to interact with it comfortably while seated. 
      In addition to its original capabilities of autonomous navigation, fleet management, conversation, gesture recognition, and face detection and recognition, Mitra 2.0 also boasts several new abilities that make it particularly well-suited for senior care. These include fall detection, which allows it to alert caregivers in the event of a fall; movement analysis tracking, which enables it to monitor and analyze the movements of seniors for signs of decline or potential issues; and guided exercises, which allow it to provide seniors with personalized exercise routines to help maintain their physical and cognitive health.
      Overall, Mitra 2.0 represents a significant advancement in the use of robotics for senior care, combining cutting-edge AI and ML technologies with a form factor that is optimized for the needs and abilities of seniors.      </Typography>
  </Container>
  <Divider sx={{marginBottom: "5%"}}></Divider>
  <Container sx={{
      minHeight: "100vh",
      display: "block",
      alignItems: "center",
      justifyContent: "center",
  }}>
      <Typography variant="h1" component="div" textAlign="center" padding="2%">
      Mitri
      </Typography>
      <img
        src={'https://static.wixstatic.com/media/023ddf_d8c3a076b99d4e8e99f256b7a7634e79~mv2.png/v1/fill/w_1095,h_431,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/mitri.png'}
        alt={"miniMitra"}
        loading="lazy"
        width="100%"
      />
      <Typography variant='p' component="div" textAlign="justify" padding="3%" >
        Mitri is a sophisticated humanoid robot designed for customer engagement and interaction. Equipped with advanced artificial intelligence (AI) and machine learning (ML) technologies, Mitri is able to identify and engage with customers in a natural and intuitive way through the use of face and emotion recognition. Its interactive display enables the delivery of targeted content, making it an effective tool for marketing and customer communication.
        In addition to its customer engagement capabilities, Mitri's autonomous navigation abilities allow it to move and interact with its environment, making it suitable for deployment in a variety of settings such as airports, hospitals, and other customer touchpoints. With its unique blend of advanced technologies and interactive capabilities, Mitri offers a highly personalized and seamless customer experience.
      </Typography>
  </Container>
  <Divider sx={{marginBottom: "5%"}}></Divider>
  <Container sx={{
      minHeight: "100vh",
      display: "block",
      alignItems: "center",
      justifyContent: "center",
  }}>
      <Typography variant="h1" component="div" textAlign="center" padding="2%">
      Spod
      </Typography>
      <img
        src={'https://static.wixstatic.com/media/023ddf_1fb3823a83b14bf784c47f89ea97760b~mv2.jpg/v1/fill/w_1024,h_403,al_c,q_85,enc_auto/Spod.jpg'}
        alt={"miniMitra"}
        loading="lazy"
        width="100%"
      />
      <Typography variant='p' component="div" textAlign="justify" padding="3%" >
      Spod is an autonomous guided vehicle (AGV) platform that is built on the same platform as the Mitra robot. It is designed to be flexible and customizable, with the ability to be integrated with various payloads such as physical hardware and the necessary power options to support them.

Spod has autonomous navigation and fleet management capabilities, allowing it to move and coordinate with other vehicles in a network. Its integrated mounting capability enables it to be fitted with various payloads, making it suitable for a wide range of applications.

Overall, Spod is a versatile AGV platform that leverages advanced artificial intelligence (AI) and machine learning (ML) technologies to enable autonomous navigation and coordination with other vehicles. Its customizable design and payload integration capabilities make it suitable for a variety of use cases.
      </Typography>
  </Container>
  <Divider sx={{marginBottom: "5%"}}></Divider>
  <Container sx={{
      minHeight: "100vh",
      display: "block",
      alignItems: "center",
      justifyContent: "center",
  }}>
      <Typography variant="h1" component="div" textAlign="center" padding="2%">
      Astra C
      </Typography>
      <img
        src={'https://static.wixstatic.com/media/023ddf_08ee71d036b344e0937729da9f258b02~mv2.png/v1/fill/w_1095,h_431,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/ub1.png'}
        alt={"miniMitra"}
        loading="lazy"
        width="100%"
      />
      <Typography variant='p' component="div" textAlign="justify" padding="3%" >
      Astra C is a specialized autonomous guided vehicle (AGV) developed as part of the Astra product line for use during the COVID-19 pandemic. It is equipped with a UV light set and power source as its payload, allowing it to perform disinfection operations at various touchpoints.

Astra C is built on the SPOD AGV platform and features autonomous navigation and fleet support capabilities. Its UV light payload allows it to effectively disinfect surfaces and objects, making it an important tool in the fight against the spread of infectious diseases.

Overall, Astra C is a valuable addition to the Astra product line, leveraging advanced artificial intelligence (AI) and machine learning (ML) technologies to enable effective disinfection operations in a variety of settings. Its autonomous navigation and fleet support capabilities make it suitable for deployment in a range of environments.
      </Typography>
  </Container>
  <Divider sx={{marginBottom: "5%"}}></Divider>
  <Container sx={{
      minHeight: "100vh",
      display: "block",
      alignItems: "center",
      justifyContent: "center",
  }}>
      <Typography variant="h1" component="div" textAlign="center" padding="2%">
      RoboDoc
      </Typography>
      <img
        src={'https://static.wixstatic.com/media/023ddf_b69d084a715b403e963e87d79c77e827~mv2.jpg/v1/crop/x_685,y_0,w_1674,h_2158/fill/w_614,h_791,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/spodir_126.jpg'}
        alt={"miniMitra"}
        loading="lazy"
        width="100%"
      />
      <Typography variant='p' component="div" textAlign="justify" padding="3%" >
      RoboDoc is a telepresence robot designed specifically for telemedicine applications. Built on top of the SPOD autonomous guided vehicle (AGV) platform, it features advanced autonomous navigation capabilities and integrated fleet management functions.

To facilitate telepresence, RoboDoc is equipped with medical internet of things (IoT) peripherals that allow it to collect and transmit medical data. This data can be accessed and analyzed by doctors through a custom-built medical dashboard, enabling them to remotely monitor and assess patients.

Overall, RoboDoc represents a major advancement in the field of telemedicine, combining the mobility and flexibility of an AGV platform with state-of-the-art medical IoT peripherals to facilitate remote patient monitoring and assessment. Its autonomous navigation capabilities and fleet management functions make it suitable for deployment in a wide range of settings.
      </Typography>
  </Container>
  <Divider sx={{marginBottom: "5%"}}></Divider>
  <Container sx={{
      minHeight: "100vh",
      display: "block",
      alignItems: "center",
      justifyContent: "center",
  }}>
      <Typography variant="h1" component="div" textAlign="center" padding="2%">
      Spod Kart
      </Typography>
      <img
        src={'https://static.wixstatic.com/media/023ddf_c05ec7d30ef54aaa8b74818c43059447~mv2.jpg/v1/fill/w_1095,h_431,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Spod_render_1_60.jpg'}
        alt={"miniMitra"}
        loading="lazy"
        width="100%"
      />
      <Typography variant='p' component="div" textAlign="justify"   padding="3%" >
      Spod Kart was an autonomous shopping cart that was developed as a research and development (R&D) project for Target retail. It was designed to assist customers with locating items inside the shopping center and providing recommendations based on their previous shopping behavior.

Spod Kart was built on the SPOD platform and featured a recommendation engine and checkout options integrated into the cart itself. Its autonomous capabilities allowed it to navigate the shopping center and provide assistance to customers as they shopped.

Overall, Spod Kart represented an innovative approach to shopping cart technology, leveraging artificial intelligence (AI) and machine learning (ML) to provide a personalized and seamless shopping experience for customers. Its integration with the SPOD platform allowed it to take advantage of advanced navigation and recommendation capabilities.
      </Typography>
  </Container>
  <Divider sx={{marginBottom: "5%"}}></Divider>
  <Container sx={{
      minHeight: "100vh",
      display: "block",
      alignItems: "center",
      justifyContent: "center",
  }}>
      <Typography variant="h1" component="div" textAlign="center" padding="2%">
      Astra R
      </Typography>
      <img
        src={'https://static.wixstatic.com/media/023ddf_4af37e2c812943f5928c37bd7129d94c~mv2.jpg/v1/fill/w_1095,h_431,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Astra_Tele%26UV_Payloads.jpg'}
        alt={"miniMitra"}
        loading="lazy"
        width="100%"
      />
      <Typography variant='p' component="div" textAlign="justify" padding="3%" >
      Astra R is a specialized autonomous guided vehicle (AGV) developed as part of the Astra product line for use during the COVID-19 pandemic. It is equipped with a UV light set or telemedicine hardware along with the necessary power source as its payload, allowing it to perform disinfection operations or facilitate telemedicine at various touchpoints.

Astra R is designed to navigate on rough surfaces and features larger wheels for improved stability and maneuverability. Its autonomous navigation and fleet support capabilities allow it to effectively perform disinfection or telemedicine operations in a variety of settings.

Overall, Astra R represents a valuable addition to the Astra product line, leveraging advanced artificial intelligence (AI) and machine learning (ML) technologies to enable effective disinfection or telemedicine operations in a range of environments. Its larger wheels and autonomous navigation capabilities make it suitable for deployment on rough surfaces.

​
      </Typography>
  </Container>
  <Divider sx={{marginBottom: "5%"}}></Divider>
  <Container sx={{
      minHeight: "100vh",
      display: "block",
      alignItems: "center",
      justifyContent: "center",
  }}>
      <Typography variant="h1" component="div" textAlign="center" padding="2%">
      Mitra - Thermal Screening
      </Typography>
      <img
        src={'https://static.wixstatic.com/media/023ddf_55a0820975584447804ce388462f0926f000.jpg/v1/fill/w_774,h_360,al_c,q_80,usm_0.33_1.00_0.00,enc_auto/023ddf_55a0820975584447804ce388462f0926f000.jpg'}
        alt={"miniMitra"}
        loading="lazy"
        width="100%"
      />
      <Typography variant='p' component="div" textAlign="justify" padding="3%" >
      In this video, we take a closer look at the Mitra Robot, a humanoid used for thermal COVID-19 screening at Fortis Hospital in Bangalore. Equipped with a thermal camera and advanced facial recognition technology, Mitra is able to quickly and accurately detect fever and other signs of illness.

As people approach the robot, it scans their faces and greets them by name, explaining the purpose of the screening. Using animation, the video illustrates the process happening inside the robot as it analyzes each person's face using thermal imaging and facial recognition software.
      </Typography>
  </Container>
  <Divider sx={{marginBottom: "5%"}}></Divider>
 
  </div>
    
  )
}

export default Robots
