import { 
         Grid,
         Container
        } from '@mui/material'
import React from 'react'
import Details from './Details'
import Form from './Form'


const Contact = () => {
  return (
    <Container maxWidth="100vw" sx={{
        bgcolor: "#F6F6F6",
        height: "100vh",
        display: "flex",
        alignItems: "center", 
        justifyContent: "center", 
        }}>
    <Grid container spacing={2} columns={2} sx={{
            display: "flex",
            alignItems: "center", 
            justifyContent: "center",
        }}>
        <Details/>
        <Form/>
    </Grid>
    </Container>
    
        

   
  )
}

export default Contact
